function Home() {
  return (
        <div className="App-header">
            <h1>NebulaFox</h1>
            <p>
                Software, games, websites and much more in the future.<br />This page will be updated as I make new project public.
            </p>
            <a href="https://fme.roseusfox.com/" style={{ color: "white" }}>Fox Media Encoder</a>

            <form action="./contacts">
                <button type="submit" className="contacts-btn">
                    Contacts
                </button>
            </form>
        </div>
  );
}

export default Home;