import './App.css';

function Contacts() {
  return (
        <header className="App-header">
          <h1>Contacts</h1>
          <p>
                <b>E-Mail: </b> <a href="mailto:roseusfox@outlook.com" style={{ color: "white" }}>
                    roseusfox@outlook.com
                </a>
          </p>
          <p>
              <b>Discord: </b> @roseus
          </p>
        </header>
  );
}

export default Contacts;