import { useCallback } from "react";
import './App.css';
import Particles from "react-particles";
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';

import { Engine } from "tsparticles-engine";
import { loadStarsPreset } from "tsparticles-preset-stars";
import Home from "./Home";
import Contacts from "./Contacts";

function App() {
    const particlesInit = useCallback(async (engine: Engine) => {
        loadStarsPreset(engine);
    }, []);
    const options = {
        preset: "stars",
    };
    //const navigate = useNavigate();

    return (
        <div className="App">
            <Particles options={options} init={particlesInit} className={`space-background`} />
            <BrowserRouter basename="/">
                <Routes>
                    <Route path="/" Component={Home} />
                    <Route path="/contacts" Component={Contacts} />
                </Routes>
            </BrowserRouter>
        </div>
  );
}

export default App;
